@import '../../config';

.label {
    color: $colorGrey60Alpha;
    display: block;
    font-size: 1rem;
    left: calc(1rem + 1px);
    line-height: 1.25;
    pointer-events: none;;
    position: absolute;
    top: 1.25rem;
    // top: 50%;
    // transform: translateY(-50%);
    transition:
        font-size 100ms linear,
        transform 100ms linear,
        top 100ms linear;
    z-index: 3;

    span {
        color: $colorError;
        margin-left: .3125rem;
    }
}

.group {
    margin-bottom: 1.25rem;
    position: relative;
    z-index: 1;

    &.focus {
        z-index: 2;

        .label {
            font-size: .75rem;
            top: .5rem;
            transform: translateY(0%);
            line-height: 1rem;
        }

        input::placeholder {
            color: #b6c6e3;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.hasError {
        .label {
            color: $colorError;
        }
    }

    .info {
        color: $colorGrey60Alpha;
        display: block;
        font-size: .75rem;
        line-height: 1rem;
        margin: .5rem 0 0 1rem;
    }

    &.hasError .info {
        color: $colorError;
    }

    &.disabled {
        opacity: .5;
        cursor: default !important;
        pointer-events: none;
    }
}
