@import '../../RbKit/config';

.wizard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    position: relative;

    > div {
        flex: 1;
        flex-basis: 25%;
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        transition: opacity 150ms linear;
        opacity: .5;

        &:before, &:after {
            content: '';
            height: 4px;
            background: $colorRocheBlue;
            left: 50%;
            opacity: 1;
            position: absolute;
            top: 13px;
            transition: width 300ms ease-in;
            width: 0;
        }

        &:before {
            background: $colorBlue40;
            width: 92%;
        }

        &.active, &:hover {
            opacity: 1;
            &:before {
                opacity: .5;
            }
        }

        &.done:after {
            width: 92%;
        }

        > div {
            background: #ffffff;
            border: solid 3px $colorRocheBlue;
            border-radius: 100%;
            height: 2rem;
            font-weight: 500;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            position: relative;
            margin-bottom: 5px;
            z-index: 3;
        }

        span {
            font-weight: 500;
        }

        &.active span {
            color: $colorRocheBlue;
        }
    }

    > div:last-child .step:before {
        display: none;
    }
}

.product {
    background: $colorBlue10;
    margin-bottom: 1rem;
    padding: 1rem;

    .productFields {
        align-items: center;
        display: flex;

        > div {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }

        .amount, .dosage {

        }

        .sample {
            min-width: 33.3334%;

            span {
                display: block;
                font-weight: 500;
                font-size: 20px;
                line-height: 1;
            }
        }
    }

    .info {
        margin-top: .5rem;
        font-size: 14px;
        font-style: italic;
    }
}

.overview {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;

    .col {
        display: flex;
        flex-direction: column;

        .block + .block {
            margin-top: 1rem;
        }
    }

    .block {
        background: $colorBlue10;
        padding: 1rem;
        height: 100%;

        p {
            display: flex;
            margin: 0;

            b {
                color: $colorGrey60Alpha;
                display: block;
                width: 100px;
            }
        }
    }
}

.checkbox {
    margin-top: 1rem;
}

.subrow {
    td {
        background: $colorBlue10 !important;
        border: 0 !important;
        line-height: 1.6 !important;

        b {
            font-size: 18px;
        }
    }

    .indications > div {
        > div {
            display: flex;

            span:first-child {
                color: $colorGrey60Alpha;
                display: block;
                width: 110px;
            }
        }
    }
}

.hidden {
    display: none !important;
}

.active {
    td {
        border: 0 !important;
    }
}

.disclaimer {
    p, h2 {
        text-align: center;
    }
}

.buttons {
    padding: 1rem 80px;

    > div {
        align-items: center;
        display: flex;
        background-color: $colorBlue10;
        padding: .5rem;
        margin-bottom: .5rem;

        &:last-child {
            background-color: $colorGrey2;
        }

        a, button {
            height: 100%;
            width: 80px;
        }

        p {
            margin: 0;
            padding: .5rem 1.5rem;
            flex: 1;
            text-align: left;
            line-height: 1.2;
            font-size: .75rem;
        }
    }
}

.productDisclaimer{
    padding: 10px;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 8px;
    
    p {
        margin: 0;
        text-align: left;

        span {
            color: #dd0000;
        }
    }

    .buttons {
        padding: 1rem 0 0 0;

        > div {
            cursor: pointer;
            background-color: $colorGrey2;
        }
    }
}

.noBorder {
    background: rgba(0, 0, 0, .05);
    border: 0 !important;
    > div, input {
        border: 0 !important;
    }
}
