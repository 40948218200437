@import '../../RbKit/config';

.container {
    align-items: center;
    background: $colorWhite;
    box-shadow: 0 5px 12px rgba(13, 31, 51, .12);
    display: flex;
    height: 4rem;
    justify-content: center;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    @include portrait {
        padding: 0 3rem;
    }

    > div:first-child {
        flex: 1;

        h1 {
            color: $colorRocheBlue;
            margin: 0;
        }
    }
}

.account {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    @include portrait {
        padding-right: 1.5rem;
    }

    > a svg {
        cursor: pointer;
        font-size: 1.5rem;
        margin-right: 1rem;
        color: $colorRocheBlue;
    }

    .avatar {
        @include fullCenter;
        background-color: $colorBlue40;
        border-radius: 100%;
        cursor: pointer;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1;
        height: 2.5rem;
        width: 2.5rem;
    }
}

.logo {
    height: 2rem;
}
