@import './config';

* {
    box-sizing: border-box;
    outline: none;
}

html {
    font-size: 16px;
}

body {
    background-color: $colorBackground;
    color: $colorGrey90Alpha;
    font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.65;
    margin: 0;
    overflow: hidden;
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4;
    margin: 0 0 1.5rem 0;
}

h1, .h1 { font-size: 1.25rem; }
h2, .h2 { font-size: 1.25rem; }
// h3, .h3 { font-size: 1.25rem; }
// h4, .h4 { font-size: 1.25rem; }
// h5, .h5 { font-size: 1.15rem; }
// h6, .h6 { font-size: 1rem; }

ol,
p,
ul {
    margin: 0 0 1.75rem 0;

    &:last-child {
        margin-bottom: 0;
    }
}

a {
    color: $colorRocheBlue;
    text-decoration: none;
    transition: color 150ms linear;

    &:hover {
        color: $colorGrey90Alpha;
        text-decoration: none;
    }
}

.error {
    color: $colorError;
}

.success {
    color: $colorSuccess;
}

.container-small {
    margin: 0 auto;
    max-width: 768px;
}
