@import '../../config';

.base {
    cursor: pointer;
    position: relative;
    z-index: 1;

    &.focus {
        z-index: 2;
    }
}

.input {
    background-color: $colorWhite;
    border: solid 1px $colorGrey20;
    border-radius: 2px;
    color: $colorGrey90Alpha;
    display: block;    
    font-size: 1rem;
    font-weight: 400;
    max-height: calc(3.5rem + 2px);
    line-height: 1.25rem;
    position: relative;
    transition:
        border-color 150ms linear,
        box-shadow 150ms linear;
    width: 100%;
    z-index: 4;

    &:hover {
        border-color: $colorBlue60;
    }

    > svg {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    &.focus {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: $colorRocheBlue;
        padding-bottom: 1px;

        > svg {
            color: $colorRocheBlue;
            margin-top: -2px;
        }
    }

    &.error {
        border-color: $colorError;
    }

    > button {
        position: absolute !important;
        right: .5rem;
        top: 9px;
    }

    input {
        border: 0;
        color: $colorGrey90Alpha;
        display: block;    
        font-size: 1rem;
        font-weight: 400;
        height: 3.5rem;
        line-height: 1.25rem;
        padding: 1.125rem 1rem 0 1rem;
        width: 100%;

        &::placeholder {
            color: #b6c6e3;
            font-weight: 500;
        }
    }

    &.error {
        border-color: $colorError;
    }
}

.options {
    background: #ffffff;
    border: solid 1px $colorRocheBlue;
    border-radius: 2px;
    left: 0;
    max-height: 300px;
    overflow: auto;
    padding-top: 3.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    &.error {
        border-color: $colorError;
    }

    .option {
        align-items: center;
        background-color: transparent;
        color: $colorGrey90Alpha;
        display: flex;
        font-size: 1rem;
        height: 3rem;
        line-height: 1.25rem;
        padding: 0 1rem;
        text-decoration: none;
        transition:
            background-color 150ms linear,
            border-color 150ms linear,
            color 150ms linear;

        &:hover {
            background-color: $colorBlue10;
            text-decoration: none;
        }

        &.disabled {
            background-color: transparent;
            color: #cccccc;
            cursor: default;
        }
    }

    .optionGroup {
        cursor: default;
        font-weight: bold;
        
        &:hover {
            background-color: transparent;
        }
    }
}

.selectedOptions {
    > div {
        align-items: center;
        background-color: $colorWhite;
        border: solid 1px $colorGrey20;
        border-radius: 2px;
        color: $colorGrey90Alpha;
        display: flex;
        font-size: 1rem;
        font-weight: 400;
        justify-content: space-between;
        margin: .5rem 0;
        min-height: calc(3.5rem + 2px);
        line-height: 1.25rem;
        padding: 1rem;
        position: relative;
        transition:
            border-color 150ms linear,
            box-shadow 150ms linear;
        width: 100%;

        &:hover {
            border-color: $colorBlue60;
        }
    }
}

.up {
    .input {
        &.focus {
            border: solid 1px $colorRocheBlue;
            border-top: 0;
            border-radius: $borderRadius;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding-bottom: .6875rem;
            padding-top: 1px;
        }
    }

    .options {
        bottom: 0;
        padding-bottom: 2.625rem;
        padding-top: 0;
        top: auto;
    }
}
