@import '../../../RbKit/config';

.link {
    cursor: pointer;

    > a {
        align-items: center;
        border-radius: $borderRadius;
        color: $colorGrey90Alpha;
        display: flex;
        padding: 2rem 1rem;
        text-decoration: none;
        transition: background 150ms linear;

        &:hover {
            color: $colorGrey90Alpha;
            text-decoration: none;
        }

        .icon {
            align-items: center;
            background-color: $colorRocheBlue;
            border-radius: 100%;
            color: #ffffff;
            display: flex;
            font-size: 1.5rem;
            height: 4rem;
            justify-content: center;
            margin-right: 1rem;
            width: 4rem;
        }

        .info {
            flex: 1;

            h3 {
                margin-bottom: .5rem;
            }

            p {
                margin: 0;
            }
        }
    }

    &:hover > a {
        background-color: $colorBlue10;
    }
}
