@import '../../config.scss';

.base {
    background-color: $colorGrey30;
    border-radius: 20px;
    color: $colorGrey90Alpha;
    display: inline-block;
    font-size: .75rem;
    padding: .1875rem .75rem;

    &.error {
        background-color: $colorError;
        color: #ffffff;
    }

    &.info {
        background-color: $colorGrey90Alpha;
        color: #ffffff;
    }

    &.success {
        background-color: $colorSuccess;
        color: #ffffff;
    }

    &.warning {
        background-color: $colorWarning;
        color: #ffffff;
    }
}

// .densePadding { padding: .5rem; }
// .compactPadding { padding: .375rem .75rem; }
.defaultPadding { padding: .1875rem .75rem; }
.nonePadding { padding: 0; }
.paddedPadding { padding: .375rem .75rem; }
